<template>
<div>
    <v-app>
        <v-card>
            <v-card-title>Marketplaces {{$store.state.passport.user.default_store_name}}
            <v-spacer></v-spacer>
            <v-btn class="btn-starkoms-primary mt-2" @click="syncSourceStock()"> Sync Source Stock </v-btn>

                <!--
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class @click="openDialogAddMarketplace" text icon color="green">
                            <v-icon>mdi-connection</v-icon>
                        </v-btn>
                        </template>
                        <span> Agregar conector</span>
                    </v-tooltip>
                -->
            </v-card-title>
            <v-card-text>
                <v-container fluid style="display: flex; flex-wrap: wrap;">
                    <v-card class="col-12 mb-10" style=""  outlined
                        v-for="(mk, index) in marketplaces" :key="index">
                        <v-card-title>
                            {{mk.marketplace.name}} <v-spacer></v-spacer> 
                            <v-switch v-model="mk.activo" :label="`${mk.activo ? 'Activo' : 'Desactivado'}`"></v-switch>
                        </v-card-title>
                        <v-card-text>
                            <span v-if="mk.marketplace.name.indexOf('Mercadolibre') != -1 "> <b>Link de activación:</b> https://marketplace.api.starkoms.com/ml/autorizar/{{mk.store_id}} </span>
                            <v-form ref="form" v-model="valid" lazy-validation >   
                                <div v-for="(elem) in Object.keys(mk.marketplace_data)" :key="elem">
                                    <v-text-field
                                        v-model="mk.marketplace_data[elem].value"
                                        :label="elem+' ('+mk.marketplace_data[elem].type+')'"
                                        required
                                        :append-icon="mk.marketplace_data[elem].type != 'string' && mk.marketplace_data[elem].type != 'number' ? ( mk.marketplace_data[elem].type == 'array' ? 'mdi-code-array' :'mdi-code-json') : 'mdi-code-string'"
                                    ></v-text-field>
                                </div>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn class="btn-starkoms-primary mt-2" @click="sendForm(index)">
                                Guardar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-container>
            </v-card-text>
        </v-card>
    </v-app>
    <dialog-add-marketplace @success="fetchData"></dialog-add-marketplace>
</div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import DialogAddMarketplace from "@/views/oms/Configuraciones/DialogAddMarketplace.vue";
export default {
    components: {
        DialogAddMarketplace
    },
    data(){
        return {
            marketplaces: [],
            valid: true,
        }
    },
    created(){
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Configuraciones", route: "/#/configuraciones" },
            { title: "Marketplaces ", route: "/#/configuraciones/marketplaces" },
        ]);
    },
    mounted()
    {
        this.fetchData();
    },
    methods: {
        sendForm(indice){
            var vm = this;
            this.axios({
                url: 'marketplace/marketplaces/update_store',
                method: 'PUT',
                data: {
                    marketplace_id: this.marketplaces[indice].marketplace.id,
                    marketplace_data: this.marketplaces[indice].marketplace_data,
                    active: this.marketplaces[indice].activo,
                    store_id: this.marketplaces[indice].store_id,
                }
            }).then( response => {
                vm.$bvToast.toast("Datos actualizados con éxito", {
                    title: `Exito`,
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
            }).catch( error => {
                console.log(error);
            });
        },
        syncSourceStock(){
            var vm = this;
            this.axios({
                url: 'marketplace/marketplaces/sync_source_stock',
            }).then( response => {
                vm.marketplaces = [];
                window.open(response.data, '_blank');
            }).catch( error => {
                console.log(error);
            })
        },
        fetchData(){
            var vm = this;
            this.axios({
                url: 'admin/stores/default/marketplaces_data',
            }).then( response => {
                vm.marketplaces = [];
                response.data.forEach(elem => {
                    let marketplace_data = {};
                    Object.keys(elem.marketplace_data).forEach(key => {
                        console.log('key', key, typeof elem.marketplace_data[key]);
                        console.log('value', elem.marketplace_data[key])
                        if( elem.marketplace_data[key] instanceof Array){
                            marketplace_data[key] = { type: 'array' ,value: JSON.stringify(elem.marketplace_data[key])};
                            return;
                        }
                        if( typeof elem.marketplace_data[key] === 'object'){
                            marketplace_data[key] = {type: 'object', value: JSON.stringify(elem.marketplace_data[key])};
                            return;
                        }
                        if( (typeof elem.marketplace_data[key]) === 'string' || (typeof elem.marketplace_data[key]) === 'number'){
                            marketplace_data[key] = { type: 'string', value: elem.marketplace_data[key]};
                            return;
                        }
                    });
                    vm.marketplaces.push({
                        activo: elem.activo,
                        store_id: elem.store_id,
                        marketplace: elem.marketplace,
                        marketplace_data
                    })
                });
                
            }).catch( error => {
                console.log(error);
            })
        },
        openDialogAddMarketplace(){
            this.$emit('open-dialog-add-marketplace');
        }
    }
}
</script>